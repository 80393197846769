import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useParams } from 'react-router-dom';
// material
import { styled } from '@mui/system';
import { Box, Card, Container, Stack, TextField, Typography } from '@mui/material';
// components
import CompanyForm from '../components/CompanyForm';
import { JobList } from '../components/_dashboard/jobs';
import Page from '../components/Page';

import { get } from '../utils/api';

// ----------------------------------------------------------------------

export default function Company() {
  const { id } = useParams();
  const [company, setCompany] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (id) {
      get(`companies/${id}`).then((res) => {
        setCompany(res.data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <Page title={`Dashboard: Company${company ? `- ${company.name}` : ''} | Agency`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {loading ? '' : company?.name || 'New Company'}
          </Typography>
        </Stack>
        {loading ? <Typography>Loading...</Typography> : <CompanyForm id={id} company={company} />}
        {Boolean(company) && (
          <Box mt={3}>
            <Typography variant="h4" gutterBottom>
              Jobs
            </Typography>
            <Card>
              <JobList jobs={company.jobs} loading={loading} />
            </Card>
          </Box>
        )}
      </Container>
    </Page>
  );
}
