import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import resumeReducer from './resumeReducer';
import notiReducer from './notiReducer';

const allReducers = combineReducers({
  resume: resumeReducer,
  account: accountReducer,
  noti: notiReducer
});

export default allReducers;
