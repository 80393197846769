import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// material
import { styled } from '@mui/system';
import { Grid, Card, Container, Stack, TextField, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import JobForm from '../components/JobForm';

import { getJob } from '../utils/database';
import { get } from '../utils/api';
// ----------------------------------------------------------------------

export default function Job() {
  const { id } = useParams();
  const [job, setJob] = useState();
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);

  const updateJob = () => {
    if (id) {
      get(`jobs/${id}`).then((res) => {
        setJob(res.data);
      });
    }
  };

  useEffect(() => {
    get(`companies/list`).then((res) => {
      setCompanies(res.data);
    });
    if (id) {
      get(`jobs/${id}`).then((res) => {
        setJob(res.data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <Page title="Dashboard: Job | Agency">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {!loading && (job ? `Job: ${job?.title}` : 'New Job')}
          </Typography>
        </Stack>
        {loading || companies.length === 0 ? (
          <>Loading...</>
        ) : (
          <JobForm id={id} job={job} updateJob={updateJob} companies={companies} />
        )}
      </Container>
    </Page>
  );
}
