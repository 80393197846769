// Default State
const initialState = {};

// Reducer
function reducer(state = initialState, action) {
  switch (action.type) {
    case 'LOAD_ACCOUNT':
      return action.data;
    case 'UNLOAD_ACCOUNT':
      return initialState;
    default:
      return state;
  }
}

// Exports Default
export default reducer;
