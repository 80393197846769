import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// material
import { Grid, Avatar, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../components/_dashboard/blog';
import ProfileForm from '../components/ProfileForm';

// ----------------------------------------------------------------------

export default function User() {
  const { id } = useParams();
  const users = useSelector((state) => state.user);
  const user = users[id];
  return (
    <Page title="Dashboard: User | Agency">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User | {user.name}
          </Typography>
        </Stack>

        {/* <ProfileForm /> */}
      </Container>
    </Page>
  );
}
