import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import Profile from './pages/Profile';
import DashboardApp from './pages/DashboardApp';
import Job from './pages/Job';
import Jobs from './pages/Jobs';
import Company from './pages/Company';
import Companies from './pages/Companies';
import Candidates from './pages/Candidates';
import Candidate from './pages/Candidate';
import Resumes from './pages/Resumes';
import Resume from './pages/Resume';
import Blog from './pages/Blog';
import Users from './pages/Users';
import User from './pages/User';
import NotFound from './pages/Page404';

// ----------------------------------------------------------------------

export default function Router({ authed }) {
  return useRoutes([
    {
      path: '/dashboard',
      element: authed ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" replace /> },
        { path: 'profile', element: <Profile /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'users', element: <Users /> },
        { path: 'users/:id', element: <User /> },
        { path: 'jobs', element: <Jobs /> },
        { path: 'jobs/new', element: <Job /> },
        { path: 'jobs/:id', element: <Job /> },
        { path: 'companies', element: <Companies /> },
        { path: 'companies/new', element: <Company /> },
        { path: 'companies/:id', element: <Company /> },
        { path: 'candidates', element: <Candidates /> },
        { path: 'candidates/new', element: <Candidate /> },
        { path: 'candidates/:id', element: <Candidate /> },
        { path: 'resumes', element: <Resumes /> },
        { path: 'resumes/:id', element: <Resume /> },
        { path: 'blog', element: <Blog /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: authed ? <Navigate to="/dashboard" /> : <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: authed ? <Navigate to="/dashboard" /> : <Navigate to="login" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
