export const openSnack =
  (message, severity = 'success') =>
  (dispatch) =>
    dispatch({
      type: 'SHOW_SNACK',
      data: {
        severity,
        message
      }
    });

export const closeSnack = () => (dispatch) => {
  dispatch({
    type: 'HIDE_SNACK'
  });
};

export const openAlert =
  ({ message, title = 'Alert', buttons = [] }) =>
  (dispatch) =>
    dispatch({
      type: 'SHOW_ALERT',
      data: {
        title,
        message,
        buttons
      }
    });

export const closeAlert = () => (dispatch) => {
  dispatch({
    type: 'HIDE_ALERT'
  });
};
