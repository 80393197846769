import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import plusFill from '@iconify/icons-eva/plus-fill';
import moment from 'moment';
// material
import {
  Card,
  Button,
  Stack,
  Avatar,
  Menu,
  MenuItem,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// components
import Page from '../components/Page';

import { openSnack } from '../redux/actions/notiAction';

import { post, get } from '../utils/api';
import { firebaseAuth } from '../utils/firebase';
//

// ----------------------------------------------------------------------

export default function Users() {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState();
  const [currentUid, setCurrentUid] = useState();

  const columns = [
    {
      field: 'photo',
      headerName: 'Photo',
      width: 80,
      renderCell: (params) => <Avatar src={params.value} />,
      filterable: false,
      sortable: false,
      align: 'center',
      headerAlign: 'center'
    },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: 'createdAt',
      headerName: 'Created',
      valueFormatter: (params) => moment(params.value).fromNow(),
      width: 150
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 100,
      renderCell: (params) =>
        account.role === 'admin' ? (
          <Button onClick={(e) => handleOpenRole(e, params.id)} variant="ghost">
            {sentenceCase(params.value || 'user')}
          </Button>
        ) : (
          <span>{sentenceCase(params.value || 'user')}</span>
        )
    }
  ];

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = () => {
    setLoading(true);
    get('users').then((res) => {
      setUsers(res.data);
      setLoading(false);
    });
  };

  const handleUpdateRole = (role) => {
    post(`users/${currentUid}/role`, { body: { role } })
      .then(() => {
        dispatch(openSnack('Role is updated.'));
        handleCloseRole();
        loadUsers();
      })
      .catch((err) => {
        dispatch(openSnack(err.message, 'warning'));
        handleCloseRole();
      });
  };

  const handleOpenRole = (e, uid) => {
    e.stopPropagation();
    // if (account.role >= 2 && account.role > role) {
    setAnchorEl(e.currentTarget);
    setCurrentUid(uid);
    // }
  };

  const handleCloseRole = () => {
    setAnchorEl();
    setCurrentUid();
  };

  return (
    <Page title="Dashboard: User | Agency">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
        </Stack>

        <Card>
          <DataGrid
            rows={users}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            rowHeight={75}
            checkboxSelection
            autoHeight
            getRowId={(row) => row._id}
            loading={loading}
          />
        </Card>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseRole}
        >
          <MenuItem onClick={() => handleUpdateRole('user')}>User</MenuItem>
          <MenuItem onClick={() => handleUpdateRole('admin')}>Admin</MenuItem>
          <MenuItem onClick={() => handleUpdateRole('manager')}>Manager</MenuItem>
        </Menu>
      </Container>
    </Page>
  );
}
