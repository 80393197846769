import moment from 'moment';

const weekdays = ['일', '월', '화', '수', '목', '금', '토'];

export const getInitial = (name = '') =>
  name
    .replace('(주)', '')
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('');

export const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validateName = (name) => {
  const re = /^[가-힣a-zA-Z0-9.-_]+$/i;
  return re.test(name);
};

export const capitalizeInitial = (s) => s.charAt(0).toUpperCase() + s.slice(1);

export const generateGreetings = () => {
  const currentHour = moment().format('HH');
  if (currentHour >= 3 && currentHour < 12) {
    return 'Good Morning';
  }
  if (currentHour >= 12 && currentHour < 15) {
    return 'Good Afternoon';
  }
  if (currentHour >= 15 || currentHour < 3) {
    return 'Good Evening';
  }
};

export const humanFileSize = (bytes, dp = 1) => {
  const thresh = 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    u += 1;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return `${bytes.toFixed(dp)} ${units[u]}`;
};

export const cumsum = (arr) => {
  const sumArr = [];
  let cum = 0;
  arr.forEach((c) => {
    cum += c;
    sumArr.push(cum);
  });
  return sumArr;
};
