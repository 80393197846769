export const ROLES = ['user', 'admin', 'manager', 'master'];

export const categories = [
  'software engineer',
  'hardware engineer',
  'consultant',
  'designer',
  'marketer',
  'project manager',
  'sales',
  'HR',
  'lawyer',
  'accountant',
  'stock broker',
  'CEO',
  'secretary'
];
