import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Button, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { ResumeList } from '../components/_dashboard/resumes';

// ----------------------------------------------------------------------

export default function Resumes() {
  return (
    <Page title="Dashboard: Resumes | Agency">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Resumes
        </Typography>
        <ResumeList />
      </Container>
    </Page>
  );
}
