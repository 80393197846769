import { useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/system';
import { alpha } from '@mui/material/styles';
import { Button, Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0)
}));

const IconWrapperStyle = styled(Button)(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function AppStatView(props) {
  const { label, active, total, icon, target, color } = props;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(target);
  };
  const rootStyle = {
    color: `${color}.main`,
    backgroundColor: `${color}.lighter`
  };
  const iconStyle = {
    color: `${color}.dark`
  };
  return (
    <RootStyle sx={rootStyle}>
      <IconWrapperStyle sx={iconStyle} onClick={handleClick}>
        {icon}
      </IconWrapperStyle>
      <Typography variant="h3">
        {active ? `${active} / ` : ''}
        {fShortenNumber(total)}
      </Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {label}
      </Typography>
    </RootStyle>
  );
}
