import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Button, Container, Stack, Typography, Card, Avatar, Chip } from '@mui/material';

// components
import Page from '../components/Page';
import { JobList } from '../components/_dashboard/jobs';
import { get } from '../utils/api';

// ----------------------------------------------------------------------

export default function Jobs() {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    get('jobs').then((res) => {
      setJobs(res.data);
      setLoading(false);
    });
  }, []);

  return (
    <Page title="Dashboard: Jobs | Agency">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Jobs
        </Typography>
        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 5 }}
        >
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/jobs/new"
            startIcon={<Icon icon={plusFill} />}
          >
            New Job
          </Button>
        </Stack>
        <Card>
          <JobList jobs={jobs} loading={loading} />
        </Card>
      </Container>
    </Page>
  );
}
