import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Stack, Typography, Avatar, Chip } from '@mui/material';
import { ChevronRight } from '@material-ui/icons';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import moment from 'moment';

const STATUS_COLORS = {
  HIRING: 'primary',
  HIRED: 'success',
  EXPIRED: 'error'
};

// ----------------------------------------------------------------------

export default function JobList({ jobs, loading }) {
  const navigate = useNavigate();
  const columns = [
    {
      field: 'company',
      headerName: 'Company',
      width: 200,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar src={params.value.logo} sx={{ width: 36, height: 36 }} />
          <Typography variant="subtitle2" noWrap>
            {params.value.name}
          </Typography>
        </Stack>
      )
    },
    { field: 'title', headerName: 'Title', flex: 2 },
    { field: 'category', headerName: 'Category', flex: 1 },
    {
      field: 'salary',
      headerName: 'Salary',
      type: 'number',
      valueFormatter: (params) => `${params.value[0]}M ~ ${params.value[1]}M`,
      width: 150
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (params) => (
        <Chip size="small" label={params.value} color={STATUS_COLORS[params.value]} />
      ),
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'dateTime',
      valueFormatter: (params) => moment(params.value).fromNow(),
      width: 150
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ChevronRight />}
          label="More"
          onClick={() => navigate(`/dashboard/jobs/${params.id}`)}
        />
      ]
    }
  ];

  return (
    <DataGrid
      rows={jobs}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10]}
      rowHeight={75}
      checkboxSelection
      autoHeight
      getRowId={(row) => row._id}
      loading={loading}
    />
  );
}
