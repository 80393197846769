import * as Yup from 'yup';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import { Icon } from '@iconify/react';
import moment from 'moment';
import cameraPlus from '@iconify/icons-mdi/camera-plus';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { styled } from '@material-ui/core/styles';
import {
  Button,
  Box,
  Card,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Avatar
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

import { updateUser } from '../utils/database';
import { openSnack } from '../redux/actions/notiAction';

// ----------------------------------------------------------------------

const CardStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(3)
}));

const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(18),
  height: theme.spacing(18),
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  background: theme.palette.background.neutral,
  border: '1px dashed #d9d9d9'
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(15)
}));

export default function ProfileForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.account);
  const [photo, setPhoto] = useState();

  const CompanySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    photo: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      name: user.name || '',
      photo: user.photo || '',
      note: user.note || ''
    },
    validationSchema: CompanySchema,
    onSubmit: (values, { setSubmitting }) => {
      updateUser(user.uid, values, photo).then(() => {
        dispatch(openSnack('Data is saved successfully.'));
        setSubmitting(false);
        navigate(-1);
      });
    }
  });

  const setImage = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleRemove = () => {
    console.log('remove');
  };

  const { errors, touched, values, isSubmitting, setFieldValue, handleSubmit, getFieldProps } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <CardStyle>
              <Stack>
                <Typography variant="h6" gutterBottom>
                  Photo
                </Typography>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <label htmlFor="icon-button-file">
                    <IconButtonStyle component="span">
                      {photo || values.photo ? (
                        <AvatarStyle src={photo ? URL.createObjectURL(photo) : values.photo} />
                      ) : (
                        <Icon icon={cameraPlus} />
                      )}
                    </IconButtonStyle>
                    <input
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      style={{
                        display: 'none'
                      }}
                      onChange={setImage}
                    />
                  </label>
                  <Typography variant="caption" color="textSecondary">
                    Allowed *.jpeg, *.jpg, *.png, *.gif max size of 3.1 MB
                  </Typography>
                </Box>
              </Stack>
            </CardStyle>
          </Grid>
          <Grid item xs={12} md={8}>
            <CardStyle>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Additional Information / Note"
                  {...getFieldProps('note')}
                  error={Boolean(touched.note && errors.note)}
                  helperText={touched.note && errors.note}
                />
                <Typography variant="overline">
                  Joined At: {moment(user.createdAt).format('YYYY-MM-DD HH:mm')}
                </Typography>
              </Stack>

              <Stack
                direction="row-reverse"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
              >
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Update
                </LoadingButton>
              </Stack>
            </CardStyle>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
