import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Button, Container, Stack, Typography, Card, Avatar } from '@mui/material';
import { ChevronRight } from '@material-ui/icons';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import moment from 'moment';
// components
import { CompanyList } from '../components/_dashboard/companies';
import Page from '../components/Page';

import { get } from '../utils/api';

// ----------------------------------------------------------------------

export default function Companies() {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    get('companies').then((res) => {
      setCompanies(res.data);
      setLoading(false);
    });
  }, []);

  return (
    <Page title="Dashboard: Companies | Agency">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Companies
        </Typography>
        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 5 }}
        >
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/companies/new"
            startIcon={<Icon icon={plusFill} />}
          >
            New Company
          </Button>
        </Stack>
        <Card>
          <CompanyList companies={companies} loading={loading} />
        </Card>
      </Container>
    </Page>
  );
}
