import moment from 'moment';
import { firebaseAuth, dbRef, stRef } from './firebase';
import { get, post, put } from './api';

// users
export async function getUser(uid) {
  return get(`users/${uid}`);
  // return dbRef.child('users').child(uid).once('value');
}

export function getRole(uid) {
  return dbRef.child('roles').child(uid).once('value');
}

export async function updateRole(uid, role) {
  return dbRef
    .child('roles')
    .child(uid)
    .set(role)
    .then(() => {
      dbRef.child('users').child(uid).update({ role });
    });
}

export async function updateUser(uid, data, file) {
  if (file) {
    return stRef
      .child('users')
      .child(uid)
      .child(`profile.jpg?updatedAt=${moment().unix()}`)
      .put(file)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          data.photo = downloadURL;
          return dbRef.child('users').child(uid).update(data);
        });
      });
  }
  return dbRef.child('users').child(uid).update(data);
}

// jobs
export function getJobs() {
  return dbRef.child('jobs').once('value');
}

export function getJob(key) {
  return dbRef.child('jobs').child(key).once('value');
}

export function removeJob(key) {
  return dbRef.child('jobs').child(key).remove();
}

export function saveJob(job, key = undefined) {
  if (key) {
    return updateJob(job, key);
  }
  key = dbRef.child('jobs').push().key;
  job.createdAt = moment().toISOString();
  job.status = 'HIRING';
  job.createdBy = firebaseAuth().currentUser.uid;
  return dbRef.child(`jobs`).child(key).set(job);
}

export function updateJob(job, key) {
  job.updatedAt = moment().toISOString();
  job.updatedBy = firebaseAuth().currentUser.uid;
  return dbRef.child(`jobs`).child(key).update(job);
}

// candidates
export function getCandidates() {
  return dbRef.child('candidates').once('value');
}

export function getCandidate(key) {
  return dbRef.child('candidates').child(key).once('value');
}

export function removeCandidate(key) {
  return dbRef.child('candidates').child(key).remove();
}

export async function saveCandidate(candidate, file, key = undefined) {
  if (!key) {
    key = dbRef.child('candidates').push().key;
    candidate.createdAt = moment().toISOString();
    candidate.createdBy = firebaseAuth().currentUser.uid;
  } else {
    candidate.updatedAt = moment().toISOString();
    candidate.updatedBy = firebaseAuth().currentUser.uid;
  }
  if (file) {
    return stRef
      .child('candidates')
      .child(key)
      .child(`profile.jpg?updatedAt=${moment().unix()}`)
      .put(file)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          candidate.photo = downloadURL;
          return dbRef.child('candidates').child(key).update(candidate);
        });
      });
  }
  return dbRef.child('candidates').child(key).update(candidate);
}

// companies
export function getCompanies() {
  return dbRef.child('companies').once('value');
}

export function getCompany(key) {
  return dbRef.child('companies').child(key).once('value');
}

export function removeCompany(key) {
  return dbRef.child('companies').child(key).remove();
}

export async function uploadImage(file, path) {
  const snapshot = await stRef.child(path).put(file);
  const downloadURL = await snapshot.ref.getDownloadURL();
  return downloadURL;
}

export async function saveCompany(company, file, key = undefined) {
  if (!key) {
    key = dbRef.child('companies').push().key;
    const sameCompany = await dbRef
      .child('companies')
      .orderByChild('name')
      .equalTo(company.name)
      .once('value');
    if (sameCompany.val()) {
      throw new Error(`${company.name} is already exists.`);
    }
    company.createdAt = moment().toISOString();
    company.createdBy = firebaseAuth().currentUser.uid;
  } else {
    company.updatedAt = moment().toISOString();
    company.updatedBy = firebaseAuth().currentUser.uid;
  }
  if (file) {
    return stRef
      .child('companies')
      .child(key)
      .child(`logo.jpg?updatedAt=${moment().unix()}`)
      .put(file)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          company.logo = downloadURL;
          return dbRef.child('companies').child(key).update(company);
        });
      });
  }
  return dbRef.child('companies').child(key).update(company);
}
