import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// material
import { Box, Grid, Container, Typography } from '@mui/material';
import {
  People as PeopleIcon,
  Assignment as AssignmentIcon,
  Work as WorkIcon,
  Business as BusinessIcon
} from '@material-ui/icons';
// components
import Page from '../components/Page';
import {
  AppStatView,
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates
} from '../components/_dashboard/app';

import { generateGreetings } from '../utils/functions';
import { get } from '../utils/api';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [numCandidate, setNumCandidate] = useState(0);
  const [numJobs, setNumJobs] = useState(0);
  const [numHirings, setNumHirings] = useState(0);
  const [numCompanies, setNumCompanies] = useState(0);
  const [jobCategories, setJobCategories] = useState([]);
  const [jobDaily, setJobDaily] = useState([]);
  const [candidateDaily, setCandidateDaily] = useState([]);
  const [numResumes, setNumResumes] = useState(0);
  const account = useSelector((state) => state.account);

  useEffect(() => {
    get('candidates/stats').then((res) => {
      const { count, daily } = res.data;
      setNumCandidate(count);
      setCandidateDaily(daily);
    });
    get('companies/count').then((res) => {
      setNumCompanies(res.data);
    });
    get('jobs/stats').then((res) => {
      const { categories, total, hiring, daily } = res.data;
      setJobCategories(categories);
      setJobDaily(daily);
      setNumJobs(total);
      setNumHirings(hiring);
    });
  }, []);

  return (
    <Page title="Dashboard | Agency">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">
            {generateGreetings()} Agent {account.name}!
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AppStatView
              label="Hiring / Jobs"
              active={numHirings}
              total={numJobs}
              target="/dashboard/jobs"
              icon={<WorkIcon />}
              color="primary"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppStatView
              label="Companies"
              total={numCompanies}
              target="/dashboard/companies"
              icon={<BusinessIcon />}
              color="info"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppStatView
              label="Candidates"
              total={numCandidate}
              target="/dashboard/candidates"
              icon={<PeopleIcon />}
              color="error"
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppStatView
              label="Resumes"
              total={Object.keys(resumes).length}
              target="/dashboard/resumes"
              icon={<AssignmentIcon />}
              color="secondary"
            />
          </Grid> */}

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits daily={jobDaily} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits categories={jobCategories} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
