import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/system';
import {
  Rating,
  Container,
  Grid,
  Stack,
  Typography,
  Card,
  Box,
  TextField,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Link
} from '@mui/material';
import { Image as ImageIcon, PictureAsPdf, AttachFile } from '@material-ui/icons';
import { LoadingButton } from '@material-ui/lab';
import moment from 'moment';

// components
import Page from '../components/Page';
import MDTextField from '../components/MDTextField';

import { get, put } from '../utils/api';
import { openAlert } from '../redux/actions/notiAction';

// ----------------------------------------------------------------------

const CardStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(3)
}));

export default function Resume() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [resume, setResume] = useState();
  const [summary, setSummary] = useState('');
  const [recommendation, setRecommendation] = useState('');
  const [title, setTitle] = useState();
  const [rating, setRating] = useState(1);
  const [fileIdx, setFileIdx] = useState(0);

  const getResume = () => {
    get(`resumes/${id}`).then((res) => {
      const value = res.data;
      setResume(value);
      if (value.reviewedAt) {
        setTitle(value.title);
        setRating(value.rating);
        setSummary(value.summary);
        setRecommendation(value.recommendation);
      }
    });
  };

  const handleSubmit = () => {
    dispatch(
      openAlert({
        title: `Review Resume`,
        message:
          'Do you want to submit the review? This will trigger a notification to a resume owner and cannot reverted.',
        buttons: [
          {
            label: 'OK',
            callback: () =>
              put(`resumes/${id}/review`, {
                body: {
                  title,
                  rating,
                  summary,
                  recommendation
                }
              }).then(() => {
                getResume();
              })
          },
          {
            label: 'Cancel',
            focus: true
          }
        ]
      })
    );
  };

  useEffect(() => {
    getResume();
  }, []);

  return (
    <Page title="Dashboard: Resume | Agency">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Resume
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={6}>
            <CardStyle>
              <Stack spacing={3}>
                <TextField
                  margin="dense"
                  label="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <Typography variant="h6">Review</Typography>
                <Rating
                  value={rating}
                  onChange={(e, newValue) => setRating(newValue)}
                  size="large"
                  precision={0.5}
                />
                <MDTextField
                  defaultPreview={false}
                  fullWidth
                  multiline
                  maxRows={12}
                  label="Summary"
                  value={summary}
                  onChange={(e) => setSummary(e.target.value)}
                />
                <MDTextField
                  defaultPreview={false}
                  fullWidth
                  multiline
                  maxRows={16}
                  label="Recommendation"
                  value={recommendation}
                  onChange={(e) => setRecommendation(e.target.value)}
                />
                {!resume?.reviewedAt && (
                  <LoadingButton
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={!summary || !recommendation}
                  >
                    submit
                  </LoadingButton>
                )}
              </Stack>
            </CardStyle>
          </Grid>
          <Grid item xs={12} md={6}>
            {resume && (
              <CardStyle>
                {resume.files.map((file) => (
                  <ListItemButton key={file.name} component={Link} target="_blank" href={file.url}>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'orange' }}>
                        <AttachFile />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={file.name} secondary="Jan 9, 2014" />
                  </ListItemButton>
                ))}
              </CardStyle>
            )}
            {resume?.reviewedAt && (
              <Box mt={2}>
                <Typography variant="body2">
                  Reviewed at: {moment(resume.reviewedAt).format('YYYY-MM-DD HH:mm')}
                </Typography>
                <Typography variant="body2">Reviewed by: {resume.reviewedBy?.name}</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
