import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Stack, Typography, Avatar, Chip } from '@mui/material';
import { ChevronRight, LinkedIn } from '@material-ui/icons';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import moment from 'moment';
import { fNumber } from '../../../utils/formatNumber';

const STATUS_COLORS = {
  HIRING: 'primary',
  HIRED: 'success',
  EXPIRED: 'error'
};

// ----------------------------------------------------------------------

export default function CandidateList({ candidates, loading }) {
  const navigate = useNavigate();
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar src={params.row.photo} sx={{ width: 24, height: 24 }} />
          <Typography variant="subtitle2" noWrap>
            {params.value}
          </Typography>
        </Stack>
      )
    },
    { field: 'realName', headerName: 'Real Name' },
    { field: 'job', headerName: 'Job Title', flex: 1 },
    { field: 'category', headerName: 'Job Category', flex: 1 },
    {
      field: 'salary',
      headerName: 'Salary',
      type: 'number',
      valueFormatter: (params) => `₩ ${fNumber(params.value)}M`,
      width: 80
    },
    {
      field: 'desiredSalary',
      headerName: 'Desired Salary',
      type: 'number',
      valueFormatter: (params) => `₩ ${fNumber(params.value)}M`,
      width: 100
    },
    {
      field: 'years',
      headerName: 'Years',
      type: 'number',
      valueFormatter: (params) => `${params.value} Y`,
      width: 70
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'number',
      valueFormatter: (params) => moment(params.value).fromNow(),
      width: 150
    },
    {
      field: 'linkedIn',
      headerName: 'LinkedIn',
      type: 'actions',
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={params.row.linkedIn ? <LinkedIn /> : <></>}
          label="LinkedIn"
          onClick={() => window.open(params.row.linkedIn, '_blank')}
        />
      ]
    },
    {
      field: 'more',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ChevronRight />}
          label="More"
          onClick={() => navigate(`/dashboard/candidates/${params.id}`)}
        />
      ]
    }
  ];

  return (
    <DataGrid
      rows={candidates}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10]}
      rowHeight={75}
      checkboxSelection
      autoHeight
      getRowId={(row) => row._id}
      loading={loading}
    />
  );
}
