import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import { Icon } from '@iconify/react';
import cameraPlus from '@iconify/icons-mdi/camera-plus';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { styled } from '@mui/system';
import {
  Autocomplete,
  Box,
  Card,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  IconButton,
  Slider,
  MenuItem,
  Avatar
} from '@mui/material';
import { LoadingButton } from '@material-ui/lab';

import moment from 'moment';

import { uploadImage } from '../utils/database';
import { get, post, put, del } from '../utils/api';
import { openSnack, openAlert } from '../redux/actions/notiAction';

// ----------------------------------------------------------------------

const CardStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(3)
}));

const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(18),
  height: theme.spacing(18),
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  background: theme.palette.background.neutral,
  border: '1px dashed #d9d9d9'
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(15)
}));

const categories = [
  'IT',
  'Software Sales',
  'Finance',
  'Legal',
  'Manufacturing',
  'Chemical',
  'Other'
];

export default function CompanyForm({ id, company }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logo, setLogo] = useState();
  const [companies, setCompanies] = useState([]);

  const CompanySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    category: Yup.string(),
    note: Yup.string(),
    location: Yup.string(),
    rating: Yup.number().min(1).max(10)
  });

  const formik = useFormik({
    initialValues: {
      name: company?.name || '',
      category: company?.category || 'Other',
      note: company?.note || '',
      logo: company?.logo || '',
      location: company?.location || '',
      rating: company?.rating || 1
    },
    validationSchema: CompanySchema,
    onSubmit: (values, { setSubmitting }) => {
      if (nameCheck) {
        dispatch(openSnack(nameCheckError, 'error'));
        setSubmitting(false);
      } else {
        const saveCompany = id
          ? put(`companies/${id}`, { body: values })
          : post('companies', { body: values });
        saveCompany
          .then((res) => {
            const company = res.data;
            console.log(company);
            if (logo) {
              uploadImage(
                logo,
                `companies/${company._id}_logo.jpg?updatedAt=${moment().unix()}`
              ).then((url) => {
                put(`companies/${company._id}`, { body: { logo: url } }).then(() => {
                  dispatch(openSnack('Company is saved successfully.'));
                  setSubmitting(false);
                  navigate('/dashboard/companies');
                });
              });
            } else {
              dispatch(openSnack('Company is saved successfully.'));
              setSubmitting(false);
              navigate('/dashboard/companies');
            }
          })
          .catch((err) => {
            dispatch(openSnack(err.message, 'error'));
            setSubmitting(false);
          });

        // saveCompany(values, logo, id)
        //   .then(() => {
        //     dispatch(openSnack('Company is saved successfully.'));
        //     setSubmitting(false);
        //     navigate('/dashboard/companies');
        //   })
        //   .catch((err) => {
        //     dispatch(openSnack(err.message, 'error'));
        //     setSubmitting(false);
        //   });
      }
    }
  });

  const setImage = (e) => {
    setLogo(e.target.files[0]);
  };

  const handleRemove = () => {
    dispatch(
      openAlert({
        title: `Remove Company [${company.name}]`,
        message: 'Are you sure?',
        buttons: [
          {
            label: 'OK',
            callback: () =>
              del(`companies/${id}`)
                .then(() => {
                  dispatch(openSnack(`${values.name} is removed successfully.`));
                  navigate('/dashboard/companies');
                })
                .catch((err) => {
                  dispatch(openSnack(err.message, 'error'));
                })
          },
          {
            label: 'Cancel',
            focus: true
          }
        ]
      })
    );
  };

  const { errors, touched, values, isSubmitting, handleSubmit, setFieldValue, getFieldProps } =
    formik;

  const nameCheck =
    !id &&
    !isSubmitting &&
    companies.filter(
      (company) =>
        company.name.replace(/\s/g, '').toLowerCase() ===
        values.name.replace(/\s/g, '').toLowerCase()
    ).length > 0;
  const nameCheckError = `${values.name} already exist.`;

  useEffect(() => {
    get('companies').then((res) => {
      setCompanies(res.data);
    });
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <CardStyle>
              <Stack>
                <Typography variant="h6" gutterBottom>
                  Company Logo Photo
                </Typography>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <label htmlFor="icon-button-file">
                    <IconButtonStyle component="span">
                      {logo || values.logo ? (
                        <AvatarStyle src={logo ? URL.createObjectURL(logo) : values.logo} />
                      ) : (
                        <Icon icon={cameraPlus} />
                      )}
                    </IconButtonStyle>
                    <input
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      style={{
                        display: 'none'
                      }}
                      onChange={setImage}
                    />
                  </label>
                  <Typography variant="caption" color="textSecondary">
                    Allowed *.jpeg, *.jpg, *.png, *.gif max size of 3.1 MB
                  </Typography>
                  <Stack width="100%" direction="row" alignItems="center">
                    <Typography variant="caption">URL </Typography>
                    <TextField
                      fullWidth
                      inputProps={{ style: { padding: 5, paddingLeft: 10 } }}
                      sx={{ my: 1, ml: 1 }}
                      {...getFieldProps('logo')}
                      error={Boolean(touched.logo && errors.logo)}
                      helperText={touched.logo && errors.logo}
                    />
                  </Stack>
                </Box>
              </Stack>
            </CardStyle>
          </Grid>
          <Grid item xs={12} md={8}>
            <CardStyle>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && (errors.name || nameCheck))}
                  helperText={(touched.name && errors.name) || (nameCheck && nameCheckError)}
                />
                <Autocomplete
                  id="combo-box-category"
                  disableClearable
                  {...getFieldProps('category')}
                  options={categories}
                  onChange={(e, v) => {
                    setFieldValue('category', v);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...getFieldProps('category')}
                      label="Company Category"
                      error={Boolean(touched.category && errors.category)}
                      helperText={touched.category && errors.category}
                    />
                  )}
                />
                <TextField
                  fullWidth
                  label="Location"
                  {...getFieldProps('location')}
                  error={Boolean(touched.location && errors.location)}
                  helperText={touched.location && errors.location}
                />
                <FormControl fullWidth sx={{ px: 1 }}>
                  <Typography id="input-slider" color="textSecondary" gutterBottom>
                    Rating: {values.rating}
                  </Typography>
                  <Slider
                    label="rating"
                    {...getFieldProps('rating')}
                    valueLabelDisplay="on"
                    min={1}
                    max={10}
                    step={1}
                  />
                </FormControl>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Additional Information / Note"
                  {...getFieldProps('note')}
                  error={Boolean(touched.note && errors.note)}
                  helperText={touched.note && errors.note}
                />
              </Stack>

              <Stack
                direction="row-reverse"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
              >
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {id ? 'Update' : 'Submit'}
                </LoadingButton>
                {id && (
                  <LoadingButton
                    size="large"
                    variant="contained"
                    color="error"
                    onClick={handleRemove}
                  >
                    Delete
                  </LoadingButton>
                )}
              </Stack>
            </CardStyle>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
