import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// material
import { Container, Stack, Typography } from '@mui/material';
// components
import CandidateForm from '../components/CandidateForm';
import Page from '../components/Page';

import { get } from '../utils/api';

// ----------------------------------------------------------------------

export default function Candidate() {
  const { id } = useParams();
  const [candidate, setCandidate] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (id) {
      get(`candidates/${id}`).then((res) => {
        setCandidate(res.data);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <Page title={`Dashboard: Candidate ${candidate ? `- ${candidate.name}` : ''} | Agency`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {loading ? '' : candidate?.name || 'New Candidate'}
          </Typography>
        </Stack>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : (
          <CandidateForm id={id} candidate={candidate} />
        )}
      </Container>
    </Page>
  );
}
