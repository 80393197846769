import { useNavigate } from 'react-router-dom';
// material
import { Stack, Typography, Avatar } from '@mui/material';
import { ChevronRight } from '@material-ui/icons';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import moment from 'moment';

// ----------------------------------------------------------------------

export default function CompanyList({ companies, loading }) {
  const navigate = useNavigate();
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar src={params.row.logo} sx={{ width: 36, height: 36 }} />
          <Typography variant="subtitle2" noWrap>
            {params.value}
          </Typography>
        </Stack>
      )
    },
    { field: 'category', headerName: 'Category', width: 100 },
    { field: 'note', headerName: 'Note', flex: 1 },
    {
      field: 'jobs',
      headerName: 'Jobs',
      valueGetter: (params) => params.value.length,
      width: 70,
      align: 'center'
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      valueFormatter: (params) => moment(params.value).fromNow(),
      width: 150
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ChevronRight />}
          label="More"
          onClick={() => navigate(`/dashboard/companies/${params.id}`)}
        />
      ]
    }
  ];

  return (
    <DataGrid
      rows={companies}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10]}
      rowHeight={75}
      checkboxSelection
      autoHeight
      getRowId={(row) => row._id}
      loading={loading}
    />
  );
}
