import { dbRef } from '../../utils/firebase';

export const listenResumes = () => (dispatch) => {
  dbRef.child('resumes').on('value', (snapshot) => {
    const resumes = snapshot.val() || {};
    return dispatch({
      type: 'LOAD_RESUMES',
      data: resumes
    });
  });
};
