import { useState } from 'react';
import moment from 'moment';
import showdown from 'showdown';
import ReactMarkdown from 'react-markdown';

import { styled } from '@mui/system';
import { Box, TextField, IconButton, Typography } from '@mui/material';
import { Visibility, Edit as EditIcon } from '@material-ui/icons';

const BoxStyle = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.background.neutral,
  border: '1px solid rgba(145, 158, 171, 0.32)',
  '& ul': {
    paddingLeft: theme.spacing(2)
  }
}));

const BottomBox = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  bottom: 0,
  padding: theme.spacing(0, 2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  borderBottomLeftRadius: theme.spacing(2),
  borderBottomRightRadius: theme.spacing(2)
}));

export default function MDTextField(props) {
  const { value, defaultPreview, ...others } = props;
  const [preview, setPreview] = useState(defaultPreview);

  const handleToggle = () => {
    setPreview(!preview);
  };

  return (
    <Box position="relative">
      {preview ? (
        <BoxStyle>
          <Typography variant="caption" color="textSecondary">
            {others.label}
          </Typography>
          <Box py={1} paddingBottom={6}>
            <ReactMarkdown>{value}</ReactMarkdown>
          </Box>
        </BoxStyle>
      ) : (
        <TextField
          value={value}
          InputProps={{ style: { paddingBottom: 50, minHeight: 160 } }}
          {...others}
        />
      )}

      <BottomBox>
        <Typography variant="caption">{new Blob([value]).size} Bytes</Typography>
        <IconButton onClick={handleToggle} color="secondary">
          {preview ? <EditIcon /> : <Visibility />}
        </IconButton>
      </BottomBox>
    </Box>
  );
}
