import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import moment from 'moment';

// material
import { styled } from '@mui/system';
import {
  Avatar,
  Autocomplete,
  Box,
  Card,
  Grid,
  Stack,
  FormControl,
  FormHelperText,
  InputLabel,
  InputAdornment,
  Slider,
  TextField,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Tab,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@material-ui/lab';

import MDTextField from './MDTextField';

import { post, put, del } from '../utils/api';
import { categories } from '../utils/constant';
import { openSnack, openAlert } from '../redux/actions/notiAction';

// ----------------------------------------------------------------------

const CardStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(3)
}));

const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  background: theme.palette.background.neutral,
  border: '1px dashed #d9d9d9'
}));

const GeneralInfo = ({
  job,
  companies,
  values,
  getFieldProps,
  setFieldValue,
  touched,
  errors,
  defaultPreview
}) => (
  <Stack spacing={3}>
    {companies.length > 0 && (
      <Autocomplete
        id="combo-box-company"
        freeSolo
        disableClearable
        options={companies.map((c) => c._id)}
        value={values.companyId}
        getOptionLabel={(companyId) => companies.find((c) => c._id === companyId)?.name || ''}
        renderOption={(props, companyId) => {
          const company = companies.find((c) => c._id === companyId);
          return (
            <Box
              component="li"
              sx={{ fontSize: 15, '& > div': { mr: '10px', fontSize: 18 } }}
              {...props}
            >
              <Avatar src={company.logo} sx={{ width: 24, height: 24 }} />
              {company.name}
            </Box>
          );
        }}
        onChange={(e, v) => {
          setFieldValue('companyId', v);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...getFieldProps('companyId')}
            label="Company"
            error={Boolean(touched.companyId && errors.companyId)}
            helperText={touched.companyId && errors.companyId}
          />
        )}
      />
    )}
    <TextField
      fullWidth
      label="Job Title"
      {...getFieldProps('title')}
      error={Boolean(touched.title && errors.title)}
      helperText={touched.title && errors.title}
    />
    <Autocomplete
      id="combo-box-category"
      freeSolo
      disableClearable
      {...getFieldProps('category')}
      options={categories}
      value={values.category}
      onChange={(e, v) => {
        setFieldValue('category', v);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...getFieldProps('category')}
          label="Job Category"
          error={Boolean(touched.category && errors.category)}
          helperText={touched.category && errors.category}
        />
      )}
    />
    <MDTextField
      defaultPreview={defaultPreview}
      fullWidth
      multiline
      maxRows={12}
      label="Job Summary / Introduction"
      {...getFieldProps('summary')}
      error={Boolean(touched.summary && errors.summary)}
      helperText={touched.summary && errors.summary}
    />
    <MDTextField
      defaultPreview={defaultPreview}
      fullWidth
      multiline
      maxRows={12}
      label="Responsibilities"
      {...getFieldProps('responsibilities')}
      error={Boolean(touched.responsibilities && errors.responsibilities)}
      helperText={touched.responsibilities && errors.responsibilities}
    />
    <MDTextField
      defaultPreview={defaultPreview}
      fullWidth
      multiline
      maxRows={12}
      label="Qualifications"
      {...getFieldProps('qualifications')}
      error={Boolean(touched.qualifications && errors.qualifications)}
      helperText={touched.qualifications && errors.qualifications}
    />
    <MDTextField
      defaultPreview={defaultPreview}
      fullWidth
      multiline
      maxRows={12}
      label="Additional Information / Note"
      {...getFieldProps('note')}
      error={Boolean(touched.note && errors.note)}
      helperText={touched.note && errors.note}
    />
    <FormControl fullWidth>
      <Typography id="input-slider" gutterBottom>
        Salary Range
      </Typography>
      <Slider
        label="salary"
        {...getFieldProps('salary')}
        valueLabelDisplay="on"
        min={0}
        max={500}
        step={1}
      />
      <Box m={2} display="flex" justifyContent="space-between">
        <TextField
          type="number"
          margin="dense"
          label="Minimum salary"
          {...getFieldProps('salary.0')}
          InputProps={{
            startAdornment: <InputAdornment position="start">₩</InputAdornment>,
            endAdornment: <InputAdornment position="end">M</InputAdornment>
          }}
        />
        <TextField
          type="number"
          margin="dense"
          label="Maximum salary"
          {...getFieldProps('salary.1')}
          InputProps={{
            startAdornment: <InputAdornment position="start">₩</InputAdornment>,
            endAdornment: <InputAdornment position="end">M</InputAdornment>
          }}
        />
      </Box>
      <FormHelperText error={Boolean(touched.salary && errors.salary)}>
        {touched.salary && errors.salary}
      </FormHelperText>
    </FormControl>
    <FormControl fullWidth>
      <Typography id="input-slider" gutterBottom>
        Reward Range
      </Typography>
      <Slider
        label="salary"
        {...getFieldProps('reward')}
        valueLabelDisplay="on"
        min={0}
        max={10}
        step={0.1}
      />
      <Box m={2} display="flex" justifyContent="space-between">
        <TextField
          type="number"
          margin="dense"
          label="Minimum reward"
          {...getFieldProps('reward.0')}
          InputProps={{
            startAdornment: <InputAdornment position="start">₩</InputAdornment>,
            endAdornment: <InputAdornment position="end">M</InputAdornment>
          }}
        />
        <TextField
          type="number"
          margin="dense"
          label="Maximum reward"
          {...getFieldProps('reward.1')}
          InputProps={{
            startAdornment: <InputAdornment position="start">₩</InputAdornment>,
            endAdornment: <InputAdornment position="end">M</InputAdornment>
          }}
        />
      </Box>
      <FormHelperText error={Boolean(touched.reward && errors.reward)}>
        {touched.reward && errors.reward}
      </FormHelperText>
    </FormControl>
  </Stack>
);

const ResearchInfo = ({ getFieldProps, setFieldValue, touched, errors, defaultPreview }) => (
  <Stack spacing={3}>
    <FormControl fullWidth>
      <Typography id="input-slider" gutterBottom>
        Rating
      </Typography>
      <Slider
        label="rating"
        {...getFieldProps('rating')}
        valueLabelDisplay="on"
        min={1}
        max={10}
        step={1}
      />
    </FormControl>
    <MDTextField
      defaultPreview={defaultPreview}
      fullWidth
      multiline
      maxRows={12}
      label="Company Research"
      {...getFieldProps('research.company')}
    />
    <MDTextField
      defaultPreview={defaultPreview}
      fullWidth
      multiline
      maxRows={12}
      label="Product Research"
      {...getFieldProps('research.product')}
    />
    <MDTextField
      defaultPreview={defaultPreview}
      fullWidth
      multiline
      maxRows={12}
      label="Insider Information"
      {...getFieldProps('research.insider')}
    />
    <MDTextField
      defaultPreview={defaultPreview}
      fullWidth
      multiline
      maxRows={12}
      label="LeaderShip Research"
      {...getFieldProps('research.leadership')}
    />
    <MDTextField
      defaultPreview={defaultPreview}
      fullWidth
      multiline
      maxRows={12}
      label="Future outlook"
      {...getFieldProps('research.future')}
    />
  </Stack>
);

export default function JobForm({ id, job, updateJob, companies }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tab, setTab] = useState('general');

  const researchSchema = Yup.object().shape({
    company: Yup.string(),
    product: Yup.string(),
    insider: Yup.string(),
    leadership: Yup.string(),
    future: Yup.string()
  });

  const JobSchema = Yup.object().shape({
    companyId: Yup.string().required('Company is required'),
    category: Yup.string().oneOf(categories).required('Job Category is required'),
    title: Yup.string().required('Job title is required'),
    summary: Yup.string(),
    salary: Yup.array().of(Yup.number()),
    reward: Yup.array().of(Yup.number()),
    rating: Yup.number(),
    responsibilities: Yup.string(),
    qualifications: Yup.string(),
    note: Yup.string(),
    research: researchSchema
  });

  const formik = useFormik({
    initialValues: {
      companyId: job?.companyId || '',
      category: job?.category || '',
      title: job?.title || '',
      summary: job?.summary || '',
      salary: job?.salary || [0, 10],
      reward: job?.reward || [0, 1],
      rating: job?.rating || 10,
      responsibilities: job?.responsibilities || '',
      qualifications: job?.qualifications || '',
      note: job?.note || '',
      research: job?.research || {
        company: '',
        product: '',
        insider: '',
        leadership: '',
        future: ''
      }
    },
    validationSchema: JobSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (id) {
        put(`jobs/${id}`, { body: values }).then(() => {
          dispatch(openSnack('Job is updated successfully.'));
          setSubmitting(false);
          navigate('/dashboard/jobs');
        });
      } else {
        post(`jobs`, { body: values }).then(() => {
          dispatch(openSnack('Job is updated successfully.'));
          setSubmitting(false);
          navigate('/dashboard/jobs');
        });
      }
    }
  });

  const handleRemove = () => {
    dispatch(
      openAlert({
        title: 'Remove Job',
        message: 'Are you sure?',
        buttons: [
          {
            label: 'OK',
            callback: () =>
              del(`jobs/${id}`)
                .then(() => {
                  dispatch(openSnack('Job is removed successfully.'));
                  navigate('/dashboard/jobs');
                })
                .catch((err) => {
                  dispatch(openSnack(err.message, 'error'));
                })
          },
          {
            label: 'Cancel',
            focus: true
          }
        ]
      })
    );
  };

  const { errors, touched, values, isSubmitting, setFieldValue, handleSubmit, getFieldProps } =
    formik;

  const handleChangeStatus = (e) => {
    put(`jobs/${id}`, { body: { status: e.target.value } }).then(() => {
      updateJob();
      dispatch(openSnack(`The status is now '${e.target.value}'.`));
    });
  };

  const handleChangeFeatured = (e) => {
    put(`jobs/${id}`, { body: { featured: e.target.checked } }).then(() => {
      updateJob();
      dispatch(openSnack(`This job is now '${e.target.value ? 'featured' : 'normal'}'.`));
    });
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={8}>
            <CardStyle>
              <Typography variant="h6" gutterBottom>
                Info
              </Typography>
              <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={(e, v) => setTab(v)} aria-label="lab API tabs example">
                    <Tab label="General" value="general" />
                    <Tab label="Research" value="research" />
                  </TabList>
                </Box>
                <TabPanel value="general">
                  <GeneralInfo
                    job={job}
                    companies={companies}
                    values={values}
                    getFieldProps={getFieldProps}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    errors={errors}
                    defaultPreview={Boolean(id)}
                  />
                </TabPanel>
                <TabPanel value="research">
                  <ResearchInfo
                    getFieldProps={getFieldProps}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    errors={errors}
                    defaultPreview={Boolean(id)}
                  />
                </TabPanel>
              </TabContext>
              <Stack
                direction="row-reverse"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
              >
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {id ? 'Update' : 'Submit'}
                </LoadingButton>
                {id && (
                  <LoadingButton
                    size="large"
                    variant="contained"
                    color="error"
                    onClick={handleRemove}
                  >
                    Delete
                  </LoadingButton>
                )}
              </Stack>
            </CardStyle>
          </Grid>
          {id && (
            <Grid item xs={12} md={4}>
              <CardStyle>
                <Stack spacing={3}>
                  <Typography variant="h6" gutterBottom>
                    Status
                  </Typography>
                  <FormControlLabel
                    control={<Checkbox checked={job?.featured} onChange={handleChangeFeatured} />}
                    label="Featured"
                    style={{ marginTop: 8 }}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="status-select-label">Status</InputLabel>
                    <Select
                      id="status-select"
                      label="Status"
                      value={job?.status}
                      onChange={handleChangeStatus}
                    >
                      {['HIRING', 'HIRED', 'EXPIRED'].map((c) => (
                        <MenuItem key={c} value={c}>
                          {c}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={Boolean(touched.category && errors.category)}>
                      {touched.category && errors.category}
                    </FormHelperText>
                  </FormControl>
                </Stack>
              </CardStyle>
              <Box mt={2}>
                <Typography variant="body2">
                  Created at: {moment(job.createdAt).format('YYYY-MM-DD HH:mm')}
                </Typography>
                <Typography variant="body2">Created by: {job.createdBy.name}</Typography>
              </Box>
              {job.updatedBy && (
                <Box mt={2}>
                  <Typography variant="body2">
                    Updated at: {moment(job.updatedAt).format('YYYY-MM-DD HH:mm')}
                  </Typography>
                  <Typography variant="body2">Updated by: {job.updatedBy.name}</Typography>
                </Box>
              )}
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  );
}
