import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Button, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { CandidateList } from '../components/_dashboard/candidates';
import { get } from '../utils/api';

// ----------------------------------------------------------------------

export default function Candidates() {
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    get('candidates').then((res) => {
      setCandidates(res.data);
      setLoading(false);
    });
  }, []);
  return (
    <Page title="Dashboard: Candidates | Agency">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Candidates
        </Typography>
        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 5 }}
        >
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/candidates/new"
            startIcon={<Icon icon={plusFill} />}
          >
            New Candidate
          </Button>
        </Stack>
        <CandidateList candidates={candidates} loading={loading} />
      </Container>
    </Page>
  );
}
