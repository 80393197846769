import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Stack, Typography, Avatar, Chip, Rating } from '@mui/material';
import { ChevronRight } from '@material-ui/icons';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import moment from 'moment';
import { get } from '../../../utils/api';

const STATUS_COLORS = {
  HIRING: 'primary',
  HIRED: 'success',
  EXPIRED: 'error'
};

// ----------------------------------------------------------------------

export default function ResumeList() {
  const navigate = useNavigate();
  const [resumeList, setResumeList] = useState([]);
  useEffect(() => {
    get('resumes').then((res) => {
      setResumeList(res.data);
    });
  }, []);
  // const resumes = useSelector((state) => state.resume);
  // const resumeList = Object.keys(resumes).map((key) => ({
  //   _id: key,
  //   ...resumes[key]
  // }));
  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 3,
      valueFormatter: (params) => params.value || 'Untitled resume'
    },
    {
      field: 'files',
      headerName: 'Files',
      type: 'number',
      disableColumnMenu: true,
      sortable: false,
      valueFormatter: (params) => params.value.length
    },
    {
      field: 'rating',
      headerName: 'Rating',
      flex: 1,
      renderCell: (params) => <Rating value={params.value} />
    },
    {
      field: 'uploadedAt',
      headerName: 'Upload',
      type: 'dateTime',
      width: 150,
      valueFormatter: (params) => moment(params.value).fromNow()
    },
    {
      field: 'reviewedAt',
      headerName: 'Reviewed',
      type: 'dateTime',
      width: 150,
      valueFormatter: (params) => (params.value ? moment(params.value).fromNow() : '')
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ChevronRight />}
          label="More"
          onClick={() => navigate(`/dashboard/resumes/${params.id}`)}
        />
      ]
    }
  ];

  return (
    <DataGrid
      rows={resumeList}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10]}
      rowHeight={75}
      checkboxSelection
      autoHeight
      getRowId={(row) => row._id}
    />
  );
}
