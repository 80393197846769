import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase';
import 'firebase/auth';

import { Snackbar, Alert } from '@mui/material';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import AlertDialog from './components/AlertDialog';

import { getUser } from './utils/database';
import { listenResumes } from './redux/actions/resumeAction';
import { openSnack, closeSnack, closeAlert } from './redux/actions/notiAction';

export default function App() {
  const navigate = useNavigate();
  const noti = useSelector((state) => state.noti);
  const [loading, setLoading] = useState(true);
  const [authed, setAuthed] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((_user) => {
      if (_user) {
        getUser(_user.uid)
          .then((res) => {
            const account = res.data;
            if (account) {
              if (['admin', 'manager'].includes(account.role)) {
                dispatch({
                  type: 'LOAD_ACCOUNT',
                  data: {
                    uid: _user.uid,
                    ...account
                  }
                });
                setAuthed(true);
                dispatch(listenResumes());
                setLoading(false);
              } else {
                dispatch({
                  type: 'UNLOAD_ACCOUNT'
                });
                setAuthed(false);
                setLoading(false);
                dispatch(openSnack("Wait for the administrator's permission.", 'info'));
                firebase.auth().signOut();
              }
            } else {
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
          });
      } else {
        setAuthed(false);
        setLoading(false);
      }
    });
  }, []);

  return (
    <ThemeConfig>
      <ScrollToTop />
      {loading ? <div>Loading...</div> : <Router authed={authed} />}

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={Boolean(noti.snack)}
        autoHideDuration={6000}
        onClose={() => dispatch(closeSnack())}
      >
        <Alert severity={noti.snack?.severity}>{noti.snack?.message}</Alert>
      </Snackbar>
      <AlertDialog
        open={Boolean(noti.alert)}
        message={noti.alert?.message}
        title={noti.alert?.title}
        buttons={noti.alert?.buttons}
        handleClose={() => dispatch(closeAlert())}
      />
    </ThemeConfig>
  );
}
