import moment from 'moment';
import { dbRef, firebaseAuth } from './firebase';

export async function signUp(values) {
  return firebaseAuth()
    .createUserWithEmailAndPassword(values.email, values.password)
    .then((res) => {
      saveUser({
        name: values.name,
        ...res.user
      });
    });
}

export function login(email, pw) {
  return firebaseAuth()
    .signInWithEmailAndPassword(email, pw)
    .then((res) => {
      saveLog(res.user);
    });
}

export function logout(uid) {
  dbRef.child('companies').off('value');
  dbRef.child('jobs').off('value');
  dbRef.child('users').child(uid).off('value');
  return firebaseAuth().signOut();
}

export function loginGoogle() {
  const provider = new firebaseAuth.GoogleAuthProvider();
  firebaseAuth()
    .signInWithPopup(provider)
    .then((res) => {
      if (res.additionalUserInfo.isNewUser) {
        saveUser(res.user);
      } else {
        saveLog(res.user);
      }
    });
}

export function resetPassword(email) {
  return firebaseAuth().sendPasswordResetEmail(email);
}

export async function saveUser(user) {
  return dbRef
    .child(`users/${user.uid}`)
    .set({
      photo: user.photoURL,
      name: user.displayName || user.name,
      email: user.email,
      uid: user.uid,
      createdAt: moment().toISOString()
    })
    .then(() => user);
}

export async function saveLog(user) {
  return dbRef
    .child(`users/${user.uid}`)
    .update({
      lastSignedInAt: moment().toISOString()
    })
    .then(() => user);
}
