import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({ open, handleClose, message, title, buttons = [] }) {
  // button = { label, callback }
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttons.map((button, idx) => {
          const callback = () => {
            if (button.callback) button.callback();
            handleClose();
          };
          const label = button.label || (idx === 0 ? '확인' : '취소');
          return (
            <Button
              variant={button.focus ? 'contained' : 'default'}
              onClick={callback}
              autoFocus={button.focus}
              key={`button-${idx}`}
            >
              {label}
            </Button>
          );
        })}
      </DialogActions>
    </Dialog>
  );
}
