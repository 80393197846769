import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Grid, Avatar, Container, Stack, Typography } from '@material-ui/core';
// components
import Page from '../components/Page';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../components/_dashboard/blog';
import ProfileForm from '../components/ProfileForm';

// ----------------------------------------------------------------------

export default function Profile() {
  const user = useSelector((state) => state.account);
  return (
    <Page title="Dashboard: Profile | Agency">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Profile
          </Typography>
        </Stack>

        {user.uid && <ProfileForm />}
      </Container>
    </Page>
  );
}
