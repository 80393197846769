import * as Yup from 'yup';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import { Icon } from '@iconify/react';
import cameraPlus from '@iconify/icons-mdi/camera-plus';
import {
  LinkedIn as LinkedInIcon,
  Email as EmailIcon,
  Phone as PhoneIcon
} from '@material-ui/icons';
// material
import { styled } from '@mui/system';
import {
  Autocomplete,
  Box,
  Card,
  Grid,
  Stack,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
  IconButton,
  Slider,
  Checkbox,
  InputAdornment,
  Avatar
} from '@mui/material';

import moment from 'moment';
import { LoadingButton } from '@material-ui/lab';
import MDTextField from './MDTextField';

import { uploadImage } from '../utils/database';
import { post, put, del } from '../utils/api';
import { categories } from '../utils/constant';
import { openSnack, openAlert } from '../redux/actions/notiAction';

// ----------------------------------------------------------------------

const CardStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(3)
}));

const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(16),
  height: theme.spacing(16),
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  background: theme.palette.background.neutral,
  border: '1px dashed #d9d9d9'
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(15)
}));

export default function CandidateForm({ id, candidate }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [photo, setPhoto] = useState();

  const CandidateSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    realName: Yup.string().required('Real Name is required'),
    job: Yup.string(),
    category: Yup.string().oneOf(categories),
    photo: Yup.string(),
    featured: Yup.bool(),
    salary: Yup.number().positive().integer(),
    desiredSalary: Yup.number().positive().integer(),
    years: Yup.number().min(0).integer(),
    note: Yup.string(),
    phone: Yup.string(),
    email: Yup.string().email(),
    linkedIn: Yup.string().url()
  });

  const formik = useFormik({
    initialValues: {
      name: candidate?.name || '',
      realName: candidate?.realName || '',
      job: candidate?.job || '',
      category: candidate?.category || '',
      photo: candidate?.photo || '',
      featured: candidate?.featured || false,
      salary: candidate?.salary || 0,
      desiredSalary: candidate?.desiredSalary || 0,
      years: candidate?.years || 0,
      note: candidate?.note || '',
      phone: candidate?.phone || '',
      email: candidate?.email || '',
      linkedIn: candidate?.linkedIn || ''
    },
    validationSchema: CandidateSchema,
    onSubmit: (values, { setSubmitting }) => {
      const saveCandidate = id
        ? put(`candidates/${id}`, { body: values })
        : post('candidates', { body: values });
      saveCandidate
        .then((res) => {
          const candidate = res.data;
          if (photo) {
            uploadImage(
              photo,
              `candidates/${candidate._id}_photo.jpg?updatedAt=${moment().unix()}`
            ).then((url) => {
              put(`candidates/${candidate._id}`, { body: { photo: url } }).then(() => {
                dispatch(openSnack('Candidate is saved successfully.'));
                setSubmitting(false);
                navigate('/dashboard/candidates');
              });
            });
          } else {
            dispatch(openSnack('Candidate is saved successfully.'));
            setSubmitting(false);
            navigate('/dashboard/candidates');
          }
        })
        .catch((err) => {
          dispatch(openSnack(err.message, 'error'));
          setSubmitting(false);
        });
    }
  });

  const setImage = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleRemove = () => {
    dispatch(
      openAlert({
        title: 'Remove Candidate',
        message: 'Are you sure?',
        buttons: [
          {
            label: 'OK',
            callback: () =>
              del(`candidates/${id}`)
                .then(() => {
                  dispatch(openSnack(`${values.name} is removed successfully.`));
                  navigate('/dashboard/candidates');
                })
                .catch((err) => {
                  dispatch(openSnack(err.message, 'error'));
                })
          },
          {
            label: 'Cancel',
            focus: true
          }
        ]
      })
    );
  };

  const { errors, touched, values, isSubmitting, handleSubmit, setFieldValue, getFieldProps } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={4}>
            <CardStyle>
              <Stack>
                <Typography variant="h6" gutterBottom>
                  Photo
                </Typography>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <label htmlFor="icon-button-file">
                    <IconButtonStyle component="span">
                      {photo || values.photo ? (
                        <AvatarStyle src={photo ? URL.createObjectURL(photo) : values.photo} />
                      ) : (
                        <Icon icon={cameraPlus} />
                      )}
                    </IconButtonStyle>
                    <input
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      style={{
                        display: 'none'
                      }}
                      onChange={setImage}
                    />
                  </label>
                  <Typography variant="caption" color="textSecondary">
                    Allowed *.jpeg, *.jpg, *.png, *.gif max size of 3.1 MB
                  </Typography>
                  <Stack width="100%" direction="row" alignItems="center">
                    <Typography variant="caption">URL </Typography>
                    <TextField
                      fullWidth
                      inputProps={{ style: { padding: 5, paddingLeft: 10 } }}
                      sx={{ my: 1, ml: 1 }}
                      {...getFieldProps('photo')}
                      error={Boolean(touched.photo && errors.photo)}
                      helperText={touched.photo && errors.photo}
                    />
                  </Stack>
                </Box>
              </Stack>
            </CardStyle>
          </Grid>
          <Grid item xs={12} md={4}>
            <CardStyle>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  fullWidth
                  label="Real Name"
                  {...getFieldProps('realName')}
                  error={Boolean(touched.realName) && errors.realName}
                  helperText={touched.realName && errors.realName}
                />
                <TextField
                  fullWidth
                  label="Job Title"
                  {...getFieldProps('job')}
                  error={Boolean(touched.job) && errors.job}
                  helperText={touched.job && errors.job}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.featured}
                      onChange={(e) => setFieldValue('featured', e.target.checked)}
                    />
                  }
                  label="Featured"
                  style={{ marginTop: 8 }}
                />
              </Stack>
            </CardStyle>
          </Grid>
          <Grid item xs={8}>
            <CardStyle>
              <Stack spacing={3}>
                <Autocomplete
                  id="combo-box-category"
                  freeSolo
                  disableClearable
                  {...getFieldProps('category')}
                  options={categories}
                  value={values.category}
                  onChange={(e, v) => {
                    setFieldValue('category', v);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...getFieldProps('category')}
                      label="Job Category"
                      error={Boolean(touched.category && errors.category)}
                      helperText={touched.category && errors.category}
                    />
                  )}
                />
                <TextField
                  type="number"
                  label="Salary"
                  {...getFieldProps('salary')}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₩</InputAdornment>,
                    endAdornment: <InputAdornment position="end">M</InputAdornment>
                  }}
                  error={Boolean(touched.salary && errors.salary)}
                  helperText={touched.salary && errors.salary}
                />
                <TextField
                  type="number"
                  label="Desired Salary"
                  {...getFieldProps('desiredSalary')}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₩</InputAdornment>,
                    endAdornment: <InputAdornment position="end">M</InputAdornment>
                  }}
                  error={Boolean(touched.desiredSalary && errors.desiredSalary)}
                  helperText={touched.desiredSalary && errors.desiredSalary}
                />
                <FormControl fullWidth sx={{ px: 1 }}>
                  <Typography id="input-slider" gutterBottom>
                    Years of Experiences: {values.years} y
                  </Typography>
                  <Slider label="years" {...getFieldProps('years')} min={0} max={20} step={1} />
                </FormControl>
              </Stack>
            </CardStyle>
          </Grid>
          <Grid item xs={8}>
            <CardStyle>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon />
                      </InputAdornment>
                    )
                  }}
                  {...getFieldProps('phone')}
                  error={Boolean(touched.phone) && errors.phone}
                  helperText={touched.phone && errors.phone}
                />
                <TextField
                  fullWidth
                  label="E-Mail address"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    )
                  }}
                  {...getFieldProps('email')}
                  error={Boolean(touched.email) && errors.email}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  fullWidth
                  label="LinkedIn URL"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkedInIcon />
                      </InputAdornment>
                    )
                  }}
                  {...getFieldProps('linkedIn')}
                  error={Boolean(touched.linkedIn) && errors.linkedIn}
                  helperText={touched.linkedIn && errors.linkedIn}
                />
                <MDTextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Additional Information / Note"
                  {...getFieldProps('note')}
                  error={Boolean(touched.note && errors.note)}
                  helperText={touched.note && errors.note}
                />
              </Stack>
              <Stack
                direction="row-reverse"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
              >
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {id ? 'Update' : 'Submit'}
                </LoadingButton>
                {id && (
                  <LoadingButton
                    size="large"
                    variant="contained"
                    color="error"
                    onClick={handleRemove}
                  >
                    Delete
                  </LoadingButton>
                )}
              </Stack>
            </CardStyle>
          </Grid>
        </Grid>
        {/* <Box textAlign="end">
          <Box mt={2}>
            <Typography variant="body2">
              Created at: {moment(candidate.createdAt).format('YYYY-MM-DD HH:mm')}
            </Typography>
            <Typography variant="body2">Created by: {candidate.createdBy.name}</Typography>
          </Box>
          {candidate.updatedBy && (
            <Box mt={2}>
              <Typography variant="body2">
                Updated at: {moment(candidate.updatedAt).format('YYYY-MM-DD HH:mm')}
              </Typography>
              <Typography variant="body2">Updated by: {candidate.updatedBy.name}</Typography>
            </Box>
          )}
        </Box> */}
      </Form>
    </FormikProvider>
  );
}
